import * as bootstrap from "../assets/.npm/node_modules/bootstrap";
import Swiper, { Navigation } from "../assets/.npm/node_modules/swiper";
import select2 from "../assets/.npm/node_modules/select2";
import datepicker from "../assets/.npm/node_modules/bootstrap-datepicker";
//import datepicker from "../assets/.npm/node_modules/antd/lib/date-picker"
//import stickyTableHeaders from "../assets/.npm/node_modules/sticky-table-headers";
//import init from "./init";

/* eslint-disable */
__webpack_public_path__ = window.PUBLIC_PATH;
/* eslint-enable */

$.fn.datepicker.dates["vi"] = {
  days: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  daysShort: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  daysMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  months: [
    "Thg1",
    "Thg2",
    "Thg3",
    "Thg4",
    "Thg5",
    "Thg6",
    "Thg7",
    "Thg8",
    "Thg9",
    "Thg10",
    "Thg11",
    "Thg12",
  ],
  monthsShort: [
    "Thg1",
    "Thg2",
    "Thg3",
    "Thg4",
    "Thg5",
    "Thg6",
    "Thg7",
    "Thg8",
    "Thg9",
    "Thg10",
    "Thg11",
    "Thg12",
  ],
  today: "Hôm Nay",
  clear: "Xoá",
  format: "mm/dd/yyyy",
  titleFormat: "MM yyyy" /* Leverages same syntax as 'format' */,
  weekStart: 0,
};

$(document).ready(function () {
  $("select").select2({
    minimumResultsForSearch: -1,
  });

  if ($(window).width() < 768) {
    var winH = $(window).height();
    $("body.home").css("--viewport-height", winH + "px");

    $(".header__login a").click(function (e) {
      e.preventDefault();
      $("body").addClass("open-login");
      $(this).hide();
    });

    $(".block-login__close span").click(function (e) {
      e.preventDefault();
      $("body").removeClass("open-login");
      $(".header__login a").show();
    });
  }
  // else {
  //   $('.detail').each(function(){

  //     // Cache the highest
  //     var highestBox = 0;

  //     // Select and loop the elements you want to equalise
  //     $('.detail__item', this).each(function(){
  //       // If this box is higher than the cached highest then store it
  //       if($(this).height() > highestBox) {
  //         highestBox = $(this).height();
  //       }
  //     });

  //     // Set the height of all those children to whichever was highest
  //     $('.detail__item',this).height(highestBox);
  //   });
  // }

  if ($(".select-primary").length) {
    $(".select-primary").data("select2").$container.addClass("select-primary");
  }

  let swiper = new Swiper(".slider", {
    modules: [Navigation],
    slidesPerView: 1,
    loop: true,
    navigation: {
      nextEl: ".slider-next",
      prevEl: ".slider-prev",
    },
  });

  $(".clickable-row").click(function () {
    window.location = $(this).data("href");
  });

  $(".show-nav").click(function () {
    $("body").addClass("show-nav");
    $(this).hide();
  });

  $(".nav-menu-action").click(function () {
    $("body").removeClass("show-nav");
    $(".show-nav").show();
  });

  $(".show-sub").click(function () {
    $(".show-sub").removeClass("show");
    $(this).toggleClass("show");
  });

  // cn
  $(".cn-show").click(function () {
    $(".cn-show").not(this).closest(".cn-item").removeClass("active");
    $(this).closest(".cn-item").toggleClass("active");
  });

  // Noti
  $(".user-info").click(function () {
    $(this).toggleClass("show");
    $(".user-info__content").toggleClass("show");
    $(".notification-icon").removeClass("show");
    $(".notification-content").removeClass("show");
    $("body").toggleClass("show-noti");
  });

  $(".notification-icon").click(function () {
    $(this).toggleClass("show");
    $(".notification-content").toggleClass("show");
    $(".user-info").removeClass("show");
    $(".user-info__content").removeClass("show");
    $("body").toggleClass("show-noti");
  });

  $(".notification__close").click(function () {
    $(".notification").hide();
  });

  // Close popup,notification
  $(document).click(function (e) {
    const insideIcon = $(".inside-close");
    const insideContent = $(".inside-content");
    if (
      !insideIcon.is(e.target) &&
      insideIcon.has(e.target).length === 0 &&
      !insideContent.is(e.target) &&
      insideContent.has(e.target).length === 0
    ) {
      insideIcon.removeClass("show");
      insideContent.removeClass("show");
      $("body").removeClass("show-noti");
    }

    const cnItem = $(".cn-show");
    const cnContent = $(".cn-data");
    if (
      !cnItem.is(e.target) &&
      cnItem.has(e.target).length === 0 &&
      !cnContent.is(e.target) &&
      cnContent.has(e.target).length === 0
    ) {
      $(".cn-item").removeClass("active");
    }
  });

  // Show pass

  $(".toggle-password").click(function () {
    $(this).toggleClass("active");
    var input = $($(this).attr("toggle"));
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });
  $(".info-eye").click(function () {
    $(this).closest(".info-chart__content").toggleClass("active");
  });

  // Popup
  $(".popup__close:not(.dialog),.popup__btnclose:not(.dialog)").click(
    function () {
      $(".popup").hide();
    }
  );

  $(document).click(function (e) {
    const closePop = $(".open-popup");
    const contentPop = $(".popup__wrap");
    if (
      !closePop.is(e.target) &&
      closePop.has(e.target).length === 0 &&
      !contentPop.is(e.target) &&
      contentPop.has(e.target).length === 0
    ) {
      $(".popup:not(.click-out)").hide();
    }

    if ($(window).width() < 768) {
      const closeMenu = $(".header__menu .show-nav");
      const contentMenu = $(".nav-menu");
      if (
        !closeMenu.is(e.target) &&
        closeMenu.has(e.target).length === 0 &&
        !contentMenu.is(e.target) &&
        contentMenu.has(e.target).length === 0
      ) {
        $(".dropdown-menu").removeClass("show-nav");
      }
    }

    if (
      $(".dropdown-menu").length &&
      $(".dropdown-menu").hasClass("valid") &&
      $(".dropdown").hasClass("open")
    ) {
      const dropdownOpen = $(".dropdown-toggle");
      const dropdownContent = $(".dropdown.open .dropdown-menu");
      if (
        !dropdownOpen.is(e.target) &&
        dropdownOpen.has(e.target).length === 0 &&
        !dropdownContent.is(e.target) &&
        dropdownContent.has(e.target).length === 0
      ) {
        $(".dropdown-menu li.error").removeClass("hide");
      }
    }
  });

  $(".popup__btnclose.dialog").click(function () {
    if (
      confirm("Qúy khách có chắc chắn muốn hủy việc xác thực giao dịch này?")
    ) {
      $(".popup").hide();
    } else {
      return false;
    }
  });

  // Status
  $(".status-close").click(function () {
    $(".main-content__status").hide();
  });

  // Icon fix
  $(window).scroll(function () {
    var sticky = $(".fixed"),
      scroll = $(window).scrollTop();

    if (scroll >= 170 && $(window).width() > 767) {
      sticky.addClass("show");
      $(".main-content__function").addClass("fix");
    } else {
      sticky.removeClass("show");
      $(".main-content__function").removeClass("fix");
    }
  });

  // date
  var date = new Date();

  var day = ("0" + date.getDate()).slice(-2);
  var month = ("0" + (date.getMonth() + 1)).slice(-2);

  var today = day + "/" + month + "/" + date.getFullYear();

  $('input[name="date-start"]').val(today);
  $('input[name="date-end"]').val(today);

  //input price
  function formatPriceNew(value) {
    var decimalCount;
    value.match(/\./g) === null
      ? (decimalCount = 0)
      : (decimalCount = value.match(/\./g));

    if (decimalCount.length > 1) {
      value = value.slice(0, -1);
    }

    var components = value.toString().split(".");
    if (components.length === 1) components[0] = value;
    components[0] = components[0]
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (components.length === 2) {
      components[1] = components[1].replace(/\D/g, "").replace(/^\d{3}$/, "");
    }

    if (components.join(".") != "") return components.join(".");
    else return "";
  }

  $("input.numberformat").on(
    "keyup click change paste input",
    function (event) {
      $(this).val(function (index, value) {
        if (value != "") {
          //return '$' + value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var decimalCount;
          value.match(/\./g) === null
            ? (decimalCount = 0)
            : (decimalCount = value.match(/\./g));

          if (decimalCount.length > 1) {
            value = value.slice(0, -1);
          }

          var components = value.toString().split(".");
          if (components.length === 1) components[0] = value;
          components[0] = components[0]
            .replace(/\D/g, "")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          if (components.length === 2) {
            components[1] = components[1]
              .replace(/\D/g, "")
              .replace(/^\d{3}$/, "");
          }

          if (components.join(".") != "") return components.join(".");
          else return "";
        }
      });
    }
  );

  $("input.numberformat").on("keyup", function (event) {
    if ($(this).val().length > 2) {
      var fourD = formatPriceNew($(this).val() + "0000");
      var fiveD = formatPriceNew($(this).val() + "00000");
      var sixD = formatPriceNew($(this).val() + "000000");
      $(this)
        .next(".auto-price")
        .html(
          "<ul><li>" +
            fourD +
            "</li><li>" +
            fiveD +
            "</li><li>" +
            sixD +
            "</li></ul>"
        )
        .removeClass("hide");
    } else {
      $(this).next(".auto-price").html("").addClass("hide");
    }
  });

  $(document).on("click", ".auto-price li", function () {
    $(this).closest(".auto-price").prev(".numberformat").val($(this).text());
    $(this).closest(".auto-price").addClass("hide");
  });

  // show filter
  $(".filter-advance").click(function () {
    $(this).addClass("hide");
    $(".filter-item").removeClass("hide");
  });

  //input file upload
  $(document).on("change", ".fileinput", function () {
    var filename = $(".fileinput").val().split("\\").pop();
    $(this).next().find("span").html(filename);
  });

  // danhbathuhuong
  $(".open-danhbathuhuong").click(function (e) {
    e.preventDefault();
    $(".danhbathuhuong").show();
  });

  // phuongthucxacthuc
  $(".open-phuongthuc").click(function (e) {
    e.preventDefault();
    $(".phuongthucxacthuc").show();
  });

  // dangxua
  $(".open-dangxuat").click(function (e) {
    e.preventDefault();
    $(".dangxuat").show();
  });

  //datepicker
  $(".input-datepicker").datepicker({
    isRTL: true,
    format: "dd/mm/yyyy",
    dayNamesMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
    language: "vi",
    autoclose: true,
    onSelect: function () {
      $(this).trigger("change");
    },
  });

  // input datlichchuyentien
  $('input[type="radio"]').click(function () {
    if ($(this).attr("value") == "Đặt lịch chuyển tiền") {
      $(".datlich").removeClass("hide");
    } else if ($(this).attr("value") == "Chuyển ngay") {
      $(".datlich").addClass("hide");
      $(".chuyenmotlan").addClass("hide");
      $(".chuyendinhky").addClass("hide");
    } else if ($(this).attr("value") == "Chuyển định kỳ") {
      $(".chuyendinhky").removeClass("hide");
      $(".chuyenmotlan").addClass("hide");
    } else if ($(this).attr("value") == "Chuyển 1 lần") {
      $(".chuyenmotlan").removeClass("hide");
      $(".chuyendinhky").addClass("hide");
    }
  });
  $(".dropdown-menu input").click(function (e) {
    e.stopPropagation();
  });

  $(".dropdown-toggle").click(function () {
    $(".dropdown").addClass("open");
    $("main").addClass("dropdown-open");
  });

  $(".dropdown-menu li a").click(function () {
    var valInput = $(this).find("input").val();
    var textOutput = $(this).attr("data-text");

    if (typeof valInput === "undefined") {
      var val = $(this).text();
    } else {
      var val = $(this).text() + " " + valInput + " " + textOutput;
    }

    $(".dropdown-toggle b").remove().appendTo($(".dropdown-toggle").text(val));
    $(".dropdown").removeClass("open");
    $("main").removeClass("dropdown-open");
    $(".dropdown").removeClass("valid");
    $(".dropdown-menu .error").addClass("hide");
  });

  $(".dropdown-menu #other").on("keypress", function (e) {
    if (e.keyCode == 13) {
      var valInput = $(this).val();
      var textData = $(this).closest("li").text();
      if (typeof valInput === "undefined") {
        var val = textData;
      } else {
        var val = textData + " " + valInput + " lần";
      }
      $(".dropdown-toggle b")
        .remove()
        .appendTo($(".dropdown-toggle").text(val));
      $(".dropdown").removeClass("open");
      $("main").removeClass("dropdown-open");
      $(".dropdown").removeClass("valid");
      $(".dropdown-menu .error").addClass("hide");
    }
  });

  $(".dropdown-menu .pick-enddate").on("change", function (e) {
    var valInputD = $(this).val();
    var formattedDate = new Date(valInputD);
    var d = formattedDate.getDate();
    var m = formattedDate.getMonth();
    m += 1; // JavaScript months are 0-11
    var y = formattedDate.getFullYear();
    var text = d + "/" + m + "/" + y;
    var textDataD = $(this).closest("li").text();
    if (typeof valInputD === "undefined") {
      var val = textDataD;
    } else {
      var val = textDataD + " " + text;
    }
    $(".dropdown-toggle b").remove().appendTo($(".dropdown-toggle").text(val));
    $(".dropdown").removeClass("open");
    $("main").removeClass("dropdown-open");
    $(".dropdown").removeClass("valid");
    $(".dropdown-menu .error").addClass("hide");
  });

  //table checkbox
  $('input[name="check-all"').click(function (e) {
    var table = $(e.target).closest("table");
    $("td input:checkbox", table).prop("checked", this.checked);
  });

  if ($(".table-responsive").length) {
    var tableOffset = $(".table-responsive table").offset().top;

    $(window).bind("scroll", function () {
      var offset = $(this).scrollTop() + $(".header").outerHeight();
      if (offset >= tableOffset) {
        $(".table-responsive table thead").css("top", offset - tableOffset);
      } else if (offset < tableOffset) {
        $(".table-responsive table thead").css("top", 0);
      }
    });
  }
});
